/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Slider from "react-slick"
import NoSSR from "react-no-ssr"
import BackgroundSlider from "react-background-slider"
import IRPageWrapper from "../../layouts/IRPageWrapper"
import Slider1Img from "./slider/slider1.jpg"
import Slider2Img from "./slider/slider2.jpg"
import Slider3Img from "./slider/slider3.jpg"
import Slider4Img from "./slider/slider4.jpg"
import Slider5Img from "./slider/slider5.jpg"
import Slider6Img from "./slider/slider6.jpg"
import Slider7Img from "./slider/sp.jpg"
import Slider8Img from "./slider/slider8.jpg"
import Slider9Img from "./slider/slider9.jpg"
import Slider10Img from "./slider/slider10.jpg"
import Slider11Img from "./slider/slider11.jpg"

import IRNav from "../../components/IRNav"
import BGImg from "./ir.jpg"
import HomeLogo from "../../components/HomeLogo"
import pdf11 from "./annual/2023.pdf"
import pdf10 from "./annual/2022.pdf"
import pdf9 from "./annual/2021.pdf"
import pdf7 from "./annual/2020.pdf"
import pdf6 from "./annual/2019.pdf"
import pdf5 from "./annual/2018.pdf"
import pdf1 from "./annual/2017.pdf"
import pdf2 from "./annual/2016.pdf"
import pdf3 from "./annual/2015.pdf"
import pdf4 from "./annual/2014.pdf"

// import InvestorCentrePost from '../../components/InvestorCentrePost'

export default class InvestorCentrePage extends Component {
  render() {
    // const { data } = this.props
    // const { group: events } = data.allMarkdownRemark
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    }
    return (
      <IRPageWrapper>
        <NoSSR>
          <BackgroundSlider images={[BGImg]} duration={10000} transition={0} />
        </NoSSR>
        <Helmet>
          <title>InvestorCentre - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />
                <h1 className="title" id="title-line">
                  Investor Centre
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <h1 className="title is-5">Annual Reports</h1>
              <div>
                <Slider {...settings}>
                  <div>
                    <img
                      className=""
                      style={{ objectFit: "contain", maxHeight: 500 }}
                      src={Slider11Img}
                    />
                    <a
                      className="button"
                      id="slider-button-4"
                      href={pdf11}
                      download
                    >
                      Download
                    </a>
                  </div>
                  <div>
                    <img
                      className=""
                      style={{ objectFit: "contain", maxHeight: 500 }}
                      src={Slider10Img}
                    />
                    <a
                      className="button"
                      id="slider-button-4"
                      href={pdf10}
                      download
                    >
                      Download
                    </a>
                  </div>
                  <div>
                    <img
                      className=""
                      style={{ objectFit: "contain", maxHeight: 500 }}
                      src={Slider9Img}
                    />
                    <a
                      className="button"
                      id="slider-button-4"
                      href={pdf9}
                      download
                    >
                      Download
                    </a>
                  </div>
                  <div>
                    <img
                      className=""
                      style={{ objectFit: "contain", maxHeight: 500 }}
                      src={Slider8Img}
                    />
                    <a
                      className="button"
                      id="slider-button-4"
                      href={pdf7}
                      download
                    >
                      Download
                    </a>
                  </div>
                  <div>
                    <img
                      className=""
                      style={{ objectFit: "contain", maxHeight: 500 }}
                      src={Slider6Img}
                    />
                    <a
                      className="button"
                      id="slider-button-4"
                      href={pdf6}
                      download
                    >
                      Download
                    </a>
                  </div>
                  <div>
                    <img
                      className=""
                      style={{ objectFit: "contain", maxHeight: 500 }}
                      src={Slider5Img}
                    />
                    <a
                      className="button"
                      id="slider-button-4"
                      href={pdf5}
                      download
                    >
                      Download
                    </a>
                  </div>
                  <div>
                    <a href={pdf1} download>
                      <img
                        className=""
                        style={{ objectFit: "contain", maxHeight: 500 }}
                        src={Slider1Img}
                      />
                      <a
                        className="button"
                        id="slider-button-1"
                        href={pdf1}
                        download
                      >
                        Download
                      </a>
                    </a>
                  </div>

                  <div>
                    <a href="./annual/2015.pdf" download>
                      <img
                        className=""
                        style={{ objectFit: "contain", maxHeight: 500 }}
                        src={Slider2Img}
                      />
                      <a
                        className="button"
                        id="slider-button-2"
                        href={pdf2}
                        download
                      >
                        Download
                      </a>
                    </a>
                  </div>

                  <div>
                    <img
                      className=""
                      style={{ objectFit: "contain", maxHeight: 500 }}
                      src={Slider3Img}
                    />
                    <a
                      className="button"
                      id="slider-button-3"
                      href={pdf3}
                      download
                    >
                      Download
                    </a>
                  </div>

                  <div>
                    <img
                      className=""
                      style={{ objectFit: "contain", maxHeight: 500 }}
                      src={Slider4Img}
                    />
                    <a
                      className="button"
                      id="slider-button-4"
                      href={pdf4}
                      download
                    >
                      Download
                    </a>
                  </div>
                </Slider>
              </div>
              <div>
              <a
               className=""
               href="https://www.bursamalaysia.com/trade/trading_resources/listing_directory/company-profile?stock_code=5259"
              >
              <img
                      className=""
                      style={{ objectFit: "contain", maxHeight: 500, maxWidth: 961 }}
                      src={Slider7Img}
              />
              </a>
              </div>
              <div style={{ marginTop: `50px`, marginBottom: `50px` }}>
                <h1 className="subtitle">IR Contact</h1>
                <div className="columns">
                  <div
                    className="column"
                    style={{ backgroundColor: `#efefef` }}
                  >
                    <p>
                      For Investor Relations related queries, please contact the
                      following
                    </p>
                    <br />
                    <p>
                      Norwahida binti Jaafar (Head of Corporate Finance &
                      Services)
                    </p>
                    <br />
                    <p>H/P: + 603 - 42525422</p>
                    <i className="fas fa-stroopwafel" />

                    <br />
                    <p>
                      Email: &nbsp;
                      <a
                        id="footer-email"
                        href="mailto:norwahida@eatechnique.com.my?Subject=Hello"
                        target="_top"
                      >
                        norwahida@eatechnique.com.my
                      </a>
                      &nbsp; / &nbsp;
                      <a
                        id="footer-email"
                        href="mailto:ir@eatechnique.com.my?Subject=Hello"
                        target="_top"
                      >
                        ir@eatechnique.com.my
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}
